.icon-items {
  margin: -10px;
}

.icon-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(97, 104, 118, 0.16);
  border-radius: 4px;
  padding: 10px;
  margin: 10px;
}

.icon-item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
}

.icon-item-text {
  text-align: center;
}
